<template>
    <form @submit.prevent="submitBody">
    <loading-overlay
        :active="Loading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title=title
        color="dark"
        size="lg"
        :close-on-backdrop="false"
        :show.sync="modalActive"
        class="modal-content-clasificacion-maquina"
        @update:show="$emit('close');"
        >
          <CRow class="mt-2">
            <CCol sm="12">
              <CSelect
                  :label="$t('label.classificationMachine')"
                  :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                  add-label-classes="required text-right"
                  :options="ClassificationListFormatted"
                  v-model.trim="$v.formTpMachine.TpClassificationMachineId.$model"
                  :value.sync="formTpMachine.TpClassificationMachineId"              
                  :is-valid="hasError($v.formTpMachine.TpClassificationMachineId)"
                  :invalid-feedback="errorMessage($v.formTpMachine.TpClassificationMachineId)"
              />
            </CCol>
            <CCol sm="12">
              <CInput
                  :label="$t('label.name')+' '+('(ES)')"
                  :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                  add-label-classes="required text-right"
                  v-uppercase
                  v-model.trim="$v.formTpMachine.TpMachineNameEs.$model"               
                  :is-valid="hasError($v.formTpMachine.TpMachineNameEs)"
                  :invalid-feedback="errorMessage($v.formTpMachine.TpMachineNameEs)"
                  :placeholder="$t('label.classificationMachine')+' '+('(ES)')"
                  maxlength="250"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                  :label="$t('label.name')+' '+('(EN)')"
                  :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                  add-label-classes="required text-right"
                  v-uppercase
                  v-model.trim="$v.formTpMachine.TpMachineNameEn.$model"               
                  :is-valid="hasError($v.formTpMachine.TpMachineNameEn)"
                  :invalid-feedback="errorMessage($v.formTpMachine.TpMachineNameEn)"
                  :placeholder="$t('label.classificationMachine')+' '+('(EN)')"
                  maxlength="250"
              >
              </CInput>
            </CCol>
            <CCol  sm="12">
              <CTextarea               
                :label="$t('label.description')"
                :placeholder="$t('label.description')"
                :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                add-label-classes="text-right"
                row="4"
                v-uppercase
                v-model="$v.formTpMachine.TpMachineDs.$model"  
                :is-valid="hasError($v.formTpMachine.TpMachineDs)"
                :invalid-feedback="errorMessage($v.formTpMachine.TpMachineDs)" 
              />
            </CCol>
            <CCol  sm="12" v-if="isEdit">
                <CSelect
                 :options="statusOptions"
                 :value.sync="formTpMachine.Status"
                 :label="$t('label.status')"
                 :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                 addLabelClasses="required text-right"
                 :is-valid="statusSelectColor"
                />
          </CCol>
          </CRow> 
           <template #footer>
            <CButton
                outline
                color="add"
                @click.stop="isEdit ? statusConfirmation(TpMachineItems.FgActTpMachine , formTpMachine.Status, submitBody) : submitBody()"
                :disabled="isSubmit"
            >
                <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
            </CButton>
            <CButton
                color="wipe"
                class="d-flex align-items-center"
                @click="$emit('close');"
            >
                <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
            </CButton>
        </template>   
      </CModalExtended>  
    </form>       
</template>
  
      
<script>
import { FormTpMachine } from '@/_validations/maquina/MaquinaValidation';
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { mapState } from 'vuex';
import { computed } from 'vue';
  
//Data
function data() {
  return {
    modalActive: false,
    isSubmit: false, 
    Loading: false,
    ClassificationItems:[],
    formTpMachine: {
      TpMachineId:'',
      TpClassificationMachineId:'',
      TpMachineNameEs: '',
      TpMachineNameEn: '',
      TpMachineDs:'',
      Status:'',
    },
  }
}
  
async function submitBody(){
    try {
      this.isSudmit = true;
      this.Loading = true;
      this.$v.formTpMachine.$touch();
      if (this.$v.formTpMachine.$error) {
        this.isSudmit = false;
        this.Loading=false;
        throw this.$t('label.errorsPleaseCheck');
      }
      const TpMachineJson = this.isEdit ?  {
        TpMachineId:this.formTpMachine.TpMachineId,
        TpClassificationMachineId:this.formTpMachine.TpClassificationMachineId,
        TpMachineNameEs: this.formTpMachine.TpMachineNameEs,
        TpMachineNameEn: this.formTpMachine.TpMachineNameEn,
        TpMachineDs: this.formTpMachine.TpMachineDs,
        Status: this.formTpMachine.Status,
      } : {
        TpClassificationMachineId:this.formTpMachine.TpClassificationMachineId,
        TpMachineNameEs: this.formTpMachine.TpMachineNameEs,
        TpMachineNameEn: this.formTpMachine.TpMachineNameEn,
        TpMachineDs: this.formTpMachine.TpMachineDs,
      }
      let metodo = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'TpMachine-update':'TpMachine-insert';

      await this.$http.ejecutar(metodo, ruta, TpMachineJson, { root: 'TpMachineJson' })
        .then(response => {
          if (response.status === (200 || 201)) {
              const messageSuccess = response.data.data[0].Response;
              this.resetForm();
              this.$emit('Update-list');
              this.$emit('close');
              this.notifySuccess({ text: messageSuccess });
              this.isSubmit = false;
              this.Loading=false;
            }     
        }).catch(err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
          this.isSubmit = false;
          this.Loading=false;
        }).then(() => {
          this.isSubmit = false;
          this.Loading=false;
        });
    } catch (e) {
        this.notifyError({ text: e });
        this.isSubmit = false;
        this.Loading=false;
    }
}
function toggle(newVal) {
    if(!newVal){
        this.resetForm();
        this.modalActive = newVal;
    }
}
function getdata(val) {
    this.formTpMachine.TpMachineId = val.TpMachineId
    this.formTpMachine.TpClassificationMachineId = val.TpClassificationMachineId
    this.formTpMachine.TpMachineNameEs = val.TpMachineNameEs;
    this.formTpMachine.TpMachineNameEn = val.TpMachineNameEn;
    this.formTpMachine.TpMachineDs = val.TpMachineDs;
    this.formTpMachine.Status = val.FgActTpMachine?1:0;
    this.$v.$touch();
}
function resetForm() {
    this.formTpMachine.TpMachineId = '',
    this.formTpMachine.TpClassificationMachineId = '',
    this.formTpMachine.TpMachineNameEs ='',
    this.formTpMachine.TpMachineNameEn ='',
    this.formTpMachine.TpMachineDs ='',
    this.formTpMachine.Status=  0,
    this.isSubmit=false
    this.$v.$reset();
}
function statusSelectColor() {
    return this.formTpMachine.Status === 1;
  }
function getClassificationlist() {
    //this.Loading = true;
    this.$http.get('TpClassificationMachine-list')
        .then((response) => {
            let list = response.data.data;
            if (list.length != 0) {
                this.ClassificationItems = list;
            }
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
            });
        }).then(() => {
            //this.Loading = false;
        });
}

//computed
function ClassificationListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.ClassificationItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ClassificationItems.map(function(e){
      if (e.FgActClassificationMachine) {
        chart.push({
          value: e.TpClassificationMachineId, 
          label: _lang=='en' ? e.TpClassificationMachineNameEn : e.TpClassificationMachineNameEs,
        }) 
      }    
    })
    return chart;
  }
}

export default {
    name: 'modal-tipo-maquina',
    mixins: [GeneralMixin, ModalMixin],
    props: {modal: Boolean, title: String, isEdit: Boolean, TpMachineItems: Object},
    data,
    validations: FormTpMachine,
    directives: UpperCase,
    methods: {
    submitBody,
      toggle,
      getdata,
      resetForm,
      statusSelectColor,
      getClassificationlist
      
    },
    computed: {
        ClassificationListFormatted
    //   ...mapState({
    //             branch: state => state.auth.branch
    //         }),
    },
    watch: {
        modal: function(val){
        this.modalActive = val;
        this.getClassificationlist();
            if (this.isEdit==true) {
                this.getdata(this.TpMachineItems);
            }else{
                this.resetForm();
            };
        }
    }
}
</script>
<style lang="scss">
  .modal-content-clasificacion-maquina {
    .modal-content {
      width: 80% !important;
      margin: auto !important;
      .modal-body {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
      }
    }
  }
</style>
  